import { render, staticRenderFns } from "./SelfInCreate.vue?vue&type=template&id=1145bb26&scoped=true&"
import script from "./SelfInCreate.vue?vue&type=script&lang=js&"
export * from "./SelfInCreate.vue?vue&type=script&lang=js&"
import style0 from "./SelfInCreate.vue?vue&type=style&index=0&id=1145bb26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1145bb26",
  null
  
)

export default component.exports